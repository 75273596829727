import { gsap } from "gsap";
import sections from "../data/sections.json";
import { Vector3 } from "@babylonjs/core";
import usecases from "../data/usecases.json";
import { Howl } from "howler";
import { setGlobalState, useGlobalState } from "../utils/state";
import { ApplicationDB, assetsLocation, packageApp } from "../assets/assetsLocation";
import { rotateToTarget, spiralAnimation, linearAnimation } from "../utils/libraries/CameraUtils";
import env from '../assets/crenv.env'; //lighting environment
import renv from '../assets/renv.env';
import menv from '../assets/menv3.env';
import { HemisphericLight, Color4, CubeTexture, ArcRotateCamera } from "@babylonjs/core";
import { showHotspots } from "../component/HomeComponent";
import { duration } from "@mui/material";

let IsTourOpen = true
const setTourState = (onOff) => {
	IsTourOpen = onOff
}
const toggleSectionUIs = (scene) => {
	if (!scene) return;
	const advancedTexture = scene.getTextureByName("myUI");

	if (!advancedTexture) return;

	// sections.forEach((section) => {
	//     const container = advancedTexture.getControlByName(`section-${section.id}-container`);

	//     container.alpha = 0;

	//     gsap.to(container, {
	//         alpha: container.isVisible ? 1 : 0,
	//         duration: 0.5,
	//         ease: "power1.out",
	//         duration: 2,
	//     });

	// });

	// usecases.forEach((usecase) => {
	//     const container = scene.getMeshByName(`usecase-${usecase.id}-fake-mesh`);

	//     container.alpha = 0;
	//     container.isVisible = true;


	//     gsap.to(container, {
	//         alpha: container.isVisible ? 1 : 0,
	//         duration: 0.5,
	//         ease: "power1.out",
	//         duration: 2,
	//     });

	// });
};


const lookAt = (xCoordinate, yCoordinate, zCoordinate, cameraX, cameraY, cameraZ) => {
	// Calculate the vector from the camera position to the target point
	let direction = new Vector3(xCoordinate - cameraX, yCoordinate - cameraY, zCoordinate - cameraZ);

	// Calculate the alpha angle (rotation around the vertical axis)
	let alpha = Math.atan2(direction.x, direction.z);

	// Calculate the beta angle (elevation from the horizontal plane)
	let distance = Math.sqrt(direction.x * direction.x + direction.z * direction.z);
	let beta = Math.atan2(direction.y, distance);

	if (Math.abs(alpha) > Math.abs(alpha + 2 * Math.PI))
		alpha = alpha + 2 * Math.PI;
	if (Math.abs(beta) > Math.abs(beta + 2 * Math.PI))
		beta = beta + 2 * Math.PI;
	// // Convert angles to the range expected by ArcRotateCamera
	// alpha = alpha < 0 ? alpha : alpha;
	// beta = beta < 0 ? beta + 2 * Math.PI : beta;
	return { alpha, beta }
}

const moveFirstTarget = (camera) => {
	const timeline = gsap.timeline();
	gsap.globalTimeline.add(timeline)
	const sound = new Howl({
		src: !packageApp ? `${assetsLocation}${ApplicationDB}/audio/gt_audio/1.mp3` : `../../${ApplicationDB}/audio/gt_audio/1.mp3`,
		html5: true,
		preload: true
	});

	const scene = camera.getScene();
	// console.log(camera.position.x, ":", camera.position.y,":", camera.position.z)
	spiralAnimation(scene, new Vector3(1.3, 4, -5.66), camera.position, new Vector3(-10.16, 8.45, 1.72), 1000, 0.75, () => {
		rotateToTarget(scene, new Vector3(1, 6.2, -4), camera, 0.5, () => {
			if (IsTourOpen) {
				sound.play();
				setGlobalState("UCTourId", 1);
				callNextTarget(camera, moveSixthTarget, sound);
			}
		});
	});
	// sound.on("end", function () {
	//     callNextTarget(camera, moveSecondTarget);
	//   });

};

const moveSecondTarget = (camera) => {
	const timeline = gsap.timeline();
	gsap.globalTimeline.add(timeline)
	const sound = new Howl({
		src: !packageApp ? `${assetsLocation}${ApplicationDB}/audio/gt_audio/4.mp3` : `../../${ApplicationDB}/audio/gt_audio/4.mp3`,
		html5: true,
		preload: true
	});

	const scene = camera.getScene();
	spiralAnimation(scene, new Vector3(3.6, 0.8, 7.9), camera.position, new Vector3(20, 3, 0.056), 3000, 1.75, () => {
		rotateToTarget(scene, new Vector3(12, 3, -1.9), camera, 0.6, async () => {
			setGlobalState("tourLoading", true);
			const crCamera = new ArcRotateCamera(
				`cr-camera`,
				-1.57,
				1.3,
				300,
				new Vector3(0, 0.25, -0.1332),
				scene
			);
			crCamera.lowerRadiusLimit = 1;
			scene.activeCamera = crCamera;
			const light = new HemisphericLight("HemiLight", new Vector3(0, 4, 2), scene);
			light.intensity = 1.2;
			scene.clearColor = new Color4(0, 0, 0, 0);
			scene.environmentTexture = new CubeTexture(renv, scene);
			scene.environmentIntensity = 0.6;
			scene.environmentTexture.rotationY = 0;
			scene.getMeshByName('factory-model').setEnabled(false);
			scene.getMeshByName('trees').setEnabled(false);
			scene.getMeshByName('hostel-sign').setEnabled(false);
			scene.getMeshByName('amphiText').setEnabled(false);
			scene.getMeshByName('wingText').setEnabled(false);
			scene.getMeshByName('crText').setEnabled(false);
			scene.getMeshByName('loungeText').setEnabled(false);
			while (scene.getMeshByName('single-room') == null) {
				
			}
			showHotspots(scene, "");
			await scene.getMeshByName('single-room').setEnabled(true);
			if (scene.getMeshByName('residentText'))
			await scene.getMeshByName('residentText').setEnabled(true);
		
		const timeline = gsap.timeline();
		timeline.to(crCamera, {
			radius: 300,
			duration: 1,
			ease: "power1.out",
			onComplete: () => {
				setGlobalState("tourLoading", false);
			}
		}).to(crCamera, {
			radius: 3,
				duration: 0.5,
				ease: "power1.out",
			}).to(crCamera, {
				alpha: 1.57,
				duration: 1.5,
				ease: "power1.in",
				onComplete: () => {
					showHotspots(scene, "singleroom");
					if (IsTourOpen) {
						sound.play();
						setGlobalState("UCTourId", 2);
						callNextTarget(camera, async () => {
							await scene.getMeshByName('factory-model').setEnabled(true);
							await scene.getMeshByName('trees').setEnabled(true);
							await scene.getMeshByName('single-room').setEnabled(false);
							light.dispose();
							crCamera.dispose();
							scene.environmentTexture = new CubeTexture(menv, scene);
							scene.environmentIntensity = 1;
							scene.environmentTexture.rotationY = 0.7592;
							if (scene.getLightByName("HemiLight"))
								scene.getLightByName("HemiLight").dispose();
							await showHotspots(scene, "usecase");
							moveToOuterCamera(camera);
						}, sound);
					}
				}
			});
			timeline.play();

		});
	});
};

const moveThirdTarget = async (camera) => {
	const sound = new Howl({
		src: !packageApp ? `${assetsLocation}${ApplicationDB}/audio/gt_audio/2.mp3` : `../../${ApplicationDB}/audio/gt_audio/2.mp3`,
		html5: true,
		preload: true
	});
	const timeline = gsap.timeline();
	gsap.globalTimeline.add(timeline);

	// rotate camera in y axis
	const scene = camera.getScene();
	// new Vector3(-4.783, 1.5959, -2)
	timeline.to(camera.position, {
		x: -14,
		y: 4,
		z: 5,
		duration: 0.8,
		ease: "power1.out"
	}).to(camera.position, {
		x: -4.999199084787257,
		y: 2.7343954014915175,
		z: -2.9921128139162286,
		duration: 0.8,
		ease: "power1.in",
		onComplete: () => {
			rotateToTarget(scene, new Vector3(-2.4, 2, -0.4), camera, 0.5, async () => {
				if (IsTourOpen) {
					sound.play();
					setGlobalState("UCTourId", 3);
					callNextTarget(camera, moveFourthTarget, sound);
				}
			});
		}
	})

	// 	spiralAnimation(scene, new Vector3(-2, 2, -4), camera.position, new Vector3(-7.84, 3.11, -5.527), 3000, 1.75, () => {
	// 		rotateToTarget(scene, new Vector3(-2.4, 2, -0.4), camera, 0.5, async () => {
	// 			if (IsTourOpen) {
	// 				sound.play();
	// 				setGlobalState("UCTourId", 3);
	// 				callNextTarget(camera, moveFourthTarget, sound);
	// 			}
	// 		});
	// 	});
};

const moveFourthTarget = (camera) => {
	const sound = new Howl({
		src: !packageApp ? `${assetsLocation}${ApplicationDB}/audio/gt_audio/3.mp3` : `../../${ApplicationDB}/audio/gt_audio/3.mp3`,
		html5: true,
		preload: true

	});

	const scene = camera.getScene();
	spiralAnimation(scene, new Vector3(-2.4, 2, -0.4), camera.position, new Vector3(-7, 1, 2), 2000, 1.75, () => {
		rotateToTarget(scene, new Vector3(-4.6, 0.8, 2.3), camera, 0.5, async () => {
			setGlobalState("tourLoading", true);
			const crCamera = new ArcRotateCamera(
				`cr-camera`,
				-3.14,
				1.4,
				300,
				new Vector3(0, 2, -3),
				scene
			);
			crCamera.lowerRadiusLimit = 1;
			scene.activeCamera = crCamera;
			const light = new HemisphericLight("HemiLight", new Vector3(0, 4, 2), scene);
			light.intensity = 1;
			scene.clearColor = new Color4(0, 0, 0, 0);
			scene.environmentTexture = new CubeTexture(env, scene);
			scene.environmentIntensity = 2;
			scene.environmentTexture.rotationY = 4.4592;

			scene.getMeshByName('factory-model').setEnabled(false);
			scene.getMeshByName('trees').setEnabled(false);
			scene.getMeshByName('hostel-sign').setEnabled(false);
			scene.getMeshByName('amphiText').setEnabled(false);
			scene.getMeshByName('wingText').setEnabled(false);
			scene.getMeshByName('loungeText').setEnabled(false);
			scene.getMeshByName('residentText').setEnabled(false);
			while (scene.getMeshByName('common-room') == null) {

			}
			showHotspots(scene, "");
			await scene.getMeshByName('common-room').setEnabled(true);
			scene.getMeshByName('crText').setEnabled(true);
			
			const timeline = gsap.timeline();
			timeline.to(crCamera, {
				radius: 300,
				duration: 1,
				ease: "power1.out",
				onComplete: () => {
					setGlobalState("tourLoading", false);
				}
			}).to(crCamera, {
				radius: 15,
				duration: 1.5
			}).to(crCamera, {
				radius: 5,
				duration: 0.5,
				ease: "power1.out",
			}).to(crCamera, {
				alpha: -1.57,
				duration: 1,
				ease: "power1.out",
			}).to(crCamera, {
				radius: 18,
				duration: 1,
				ease: "power1.in",
				onComplete: () => {
					showHotspots(scene, "commonroom");
					if (IsTourOpen) {
						sound.play();
						setGlobalState("UCTourId", 4);
						callNextTarget(camera, async () => {
							await scene.getMeshByName('factory-model').setEnabled(true);
							await scene.getMeshByName('trees').setEnabled(true);
							await scene.getMeshByName('common-room').setEnabled(false);
							crCamera.dispose();
							scene.environmentTexture = new CubeTexture(menv, scene);
							scene.environmentIntensity = 1;
							scene.environmentTexture.rotationY = 0.7592;
							await showHotspots(scene, "usecase");
							if (scene.getLightByName("HemiLight"))
								scene.getLightByName("HemiLight").dispose();
							moveFifthTarget(camera);
						}, sound);
					}
				}
			});
			timeline.play();

		});
	});
};

const moveFifthTarget = (camera) => {
	const sound = new Howl({
		src: !packageApp ? `${assetsLocation}${ApplicationDB}/audio/gt_audio/6.mp3` : `../../${ApplicationDB}/audio/gt_audio/6.mp3`,
		html5: true,
		preload: true

	})
	const scene = camera.getScene();
	spiralAnimation(scene, new Vector3(-4.6, 0.8, 2.3), camera.position, new Vector3(3.6, 0.8, 9), 3000, 1.5, () => {
		rotateToTarget(scene, new Vector3(3.6, 0.8, 7.9), camera, 0.6, async () => {
			setGlobalState("tourLoading", true);
			const crCamera = new ArcRotateCamera(
				`cr-camera`,
				-1.57,
				1.3,
				300,
				new Vector3(0, 2, 0),
				scene
			);
			crCamera.lowerRadiusLimit = 1;
			scene.activeCamera = crCamera;
			const light = new HemisphericLight("HemiLight", new Vector3(0, 4, 2), scene);
			light.intensity = 1;
			scene.clearColor = new Color4(0, 0, 0, 0);
			scene.environmentTexture = new CubeTexture(renv, scene);
			scene.environmentIntensity = 1;
			scene.cameraContrast = 2.6;
			scene.environmentTexture.rotationY = 1.1012;

			scene.getMeshByName('factory-model').setEnabled(false);
			scene.getMeshByName('trees').setEnabled(false);
			scene.getMeshByName('hostel-sign').setEnabled(false);
			scene.getMeshByName('amphiText').setEnabled(false);
			scene.getMeshByName('wingText').setEnabled(false);
			scene.getMeshByName('crText').setEnabled(false);
			scene.getMeshByName('residentText').setEnabled(false);
			while (scene.getMeshByName('reading-lounge') == null) {

			}
			showHotspots(scene, "");
			await scene.getMeshByName('reading-lounge').setEnabled(true);
			scene.getMeshByName('loungeText').setEnabled(true);

			const timeline = gsap.timeline();
			timeline.to(crCamera, {
				radius: 300,
				duration: 1,
				ease: "power1.out",
				onComplete: () => {
					setGlobalState("tourLoading", false);
				}
			}).to(crCamera, {
				radius: 25,
				duration: 0.5,
				ease: "power1.out",
				onComplete: () => {
					showHotspots(scene, "readinglounge");
					if (IsTourOpen) {
						sound.play();
						setGlobalState("UCTourId", 5);
						callNextTarget(camera, async () => {
							await scene.getMeshByName('factory-model').setEnabled(true);
							await scene.getMeshByName('trees').setEnabled(true);
							await scene.getMeshByName('reading-lounge').setEnabled(false);
							crCamera.dispose();
							scene.environmentTexture = new CubeTexture(menv, scene);
							scene.environmentIntensity = 1;
							scene.environmentTexture.rotationY = 0.7592;
							await showHotspots(scene, "usecase");
							if (scene.getLightByName("HemiLight"))
								scene.getLightByName("HemiLight").dispose();
							moveSecondTarget(camera);
						}, sound);
					}
				}
			});
			timeline.play();
		});
	});
};

const moveSixthTarget = async (camera) => {
	const sound = new Howl({
		src: !packageApp ? `${assetsLocation}${ApplicationDB}/audio/gt_audio/5.mp3` : `../../${ApplicationDB}/audio/gt_audio/5.mp3`,
		html5: true,
		preload: true

	})
	const timeline = gsap.timeline();
	gsap.globalTimeline.add(timeline)

	const scene = camera.getScene();
	await timeline.to(camera.position, {
		x: -14,
		y: 4,
		z: 5,
		duration: 1,
		ease: "power1.out",
		onComplete: () => {
			rotateToTarget(scene, new Vector3(-1.3, 0.8, -5.66), camera, 1,
				spiralAnimation(scene, new Vector3(-1.3, 1.1, -4), camera.position, new Vector3(-4.783, 1, -2), 1500, 1.5, () => {
					rotateToTarget(scene, new Vector3(-1.3, 0.8, -5.66), camera, 0.6, async () => {
						if (IsTourOpen) {
							sound.play();
							setGlobalState("UCTourId", 6);
							callNextTarget(camera, moveThirdTarget, sound);
						}
					});
				}))
		}
	});
};

const moveToOuterCamera = async (camera) => {
	setGlobalState("UCTourId", 0);
	const scene = camera.getScene();
	const arcRotateCamera = scene.getCameraByName('camera-2');
	const cam3 = scene.getCameraByName('camera-3');
	const canvas = document.getElementsByClassName("main-canvas")[0];

	scene.activeCamera.computeWorldMatrix();
	cam3.position.copyFrom(scene.activeCamera.position);
	cam3.setTarget(scene.activeCamera.target.clone());
	arcRotateCamera.restoreState();
	arcRotateCamera.computeWorldMatrix();
	await scene.getMeshByName('amphiText').setEnabled(true);
	await scene.getMeshByName('wingText').setEnabled(true);
	await rotateToTarget(scene, arcRotateCamera.target, cam3, 0.6, spiralAnimation, scene, arcRotateCamera.target, cam3.position, arcRotateCamera.position, 1000, 1, (arcRotateCamera, canvas) => { scene.activeCamera = arcRotateCamera; arcRotateCamera.attachControl(canvas, true); enableCameraMovement(camera); setGlobalState("IsTourOpen", false); }, arcRotateCamera, canvas);
	const sound = new Howl({
		src: !packageApp ? `${assetsLocation}${ApplicationDB}/audio/gt_audio/7.mp3` : `../../${ApplicationDB}/audio/gt_audio/5.mp3`,
		html5: true,
		preload: true
	})
	await new Promise(resolve => setTimeout(resolve, 1300));
	sound.play();
};

const moveCameraOnClose = (camera) => {

	const closeBtn = document.querySelector("#close-btn");
	// closeBtn.setAttribute("disabled", true);
	const timeline = gsap.timeline();

	const scene = camera.getScene();

	const camera2 = scene.getCameraByName("camera-2")
	camera2.restoreState();
	scene.activeCamera = camera2;
	toggleSectionUIs(scene);

	timeline
		.to(camera.position, {
			x: -16,
			y: 33,
			z: 58,
			duration: 3,
			ease: "power1.out"
		})


	timeline
		.to(camera.rotation, {
			x: 0.27,
			y: - 2.8,
			z: 0,
			duration: 3.5,
			ease: "power1.out",
			onComplete: () => {
				// here
				// const advancedTexture = scene.getTextureByName("myUI");

				// if (!advancedTexture) return;


				// const container = advancedTexture.getControlByName(`section-1-container`);

				// if (!container.isVisible){enableCameraMovement(camera);}
				enableCameraMovement(camera);

				const closeBtn = document.querySelector("#close-btn");
				closeBtn.removeAttribute("disabled");

				const startBtn = document.querySelector("#tour");
				startBtn.removeAttribute("disabled");


			}

		})
};

// const moveCameraToIT = (camera) => {

//     gsap.to(camera.position, {
//         x: -67,
//         y: 4.63,
//         z: 24.4,
//         duration: 3,
//         ease: "power1.out"
//     })

//     gsap.to(camera.rotation, {
//         x: 0.13,
//         y: -2.8,
//         z: 0,
//         duration: 3,
//     })


// };



const disableCameraMovementOnTour = (camera) => {
	camera.inputs.attached.mouse.detachControl();
};


const enableCameraMovement = (camera) => {

	// check if camera is attached to mouse input 
	if (!camera || !camera.inputs || camera.inputs.attached.mouse) return;

	// camera.inputs.attachInput(camera.inputs.attached.mouse);
	camera.inputs.addMouseWheel();
	camera.inputs.addPointers();
};

const startAnimations = (scene) => {

	scene.getCameraByName("camera-3").position = scene.activeCamera.position;

	const freeCam = scene.getCameraByName("camera-3");


	// freeCam.position = scene.activeCamera.position;
	// freeCam.setTarget(new Vector3(0, 3, 0));

	// freeCam.rotation = new Vector3(0.2797833944525906, -1.729744737715753, 0);

	scene.activeCamera = freeCam;

	const startBtn = document.querySelector("#tour");
	// startBtn.setAttribute("disabled", true);

	disableCameraMovementOnTour(freeCam);
	gsap.globalTimeline.getChildren().forEach(child => child.kill());
	const bgMusic = new Howl({
		src: !packageApp ? `${assetsLocation}${ApplicationDB}/audio/uc_music/immersive.mp3` : `../../${ApplicationDB}/audio/uc_music/immersive.mp3`,
		loop: true,
		volume: 0.15,
		html5: true
	});
	const sound = new Howl({
		src: !packageApp ? `${assetsLocation}${ApplicationDB}/audio/gt_audio/0.mp3` : `../../${ApplicationDB}/audio/gt_audio/0.mp3`,
		html5: true
	});
	bgMusic.play();
	sound.play();
	sound.on("end", function () {
		moveFirstTarget(freeCam);
	});
	// setTimeout(() => {
	// }, 1500);

};



const callNextTarget = (camera, fn, sound) => {

	sound.on("end", function () {
		setGlobalState("UCTourId", 0);
		fn(camera);
	});

}

export { startAnimations, moveCameraOnClose, toggleSectionUIs, enableCameraMovement, disableCameraMovementOnTour, setTourState };