import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import ToolbarButton from "../utils/libraries/ToolbarButton";
import MenuDispensor from "../utils/libraries/MenuDispensor";
import { useParams } from "react-router-dom";
import { setGlobalState, useGlobalState } from "../utils/state";
import { Howler, Howl } from "howler";
import "../utils/css/mainPage.css";
import {
  BaseAPI,
  MainMenuIsButtons,
  ApplicationDB,
  assetsLocation,
  packageApp,
} from "../assets/assetsLocation";
import { setTourState } from "../hooks/animations";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker";
import { CSSTransition } from "react-transition-group";
import { startTransition } from "react";
import { resetLights } from "../utils/libraries/LightUtils";

const MainPage = (props) => {
  const buttonRef = useRef(null);
  const { toPress, loadID } = useParams();
  const [extraData, setExtraData] = useState([
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]);
  const [selectedButton, setSelectedButton] = useGlobalState("selectedButton");
  const [showCardContainer, setShowCardContainer] = useState(false);
  const [sectionData, setSectionData] = useState([]);

  const [ui_Element, setUI_Element] = useState(null);

  const [buttonType, setButtonType] = useState("");

  const [showUC, setShowUC] = useGlobalState("showUC");

  const [isResetClick, setIsResetClick] = useState(false);
  const [useCaseMapping, setUseCaseMapping] = useState(false);
  const [HoverId, setHoverId] = useGlobalState("HoverId");
  const [HoverLabel, setHoverLabel] = useGlobalState("HoverLabel");
  const [SolutionsId, setSolutionsId] = useGlobalState("solutionsId");
  const [clientXPosition1, setClientXPosition1] = useGlobalState("clientXPosition1");
  const [clientYPosition1, setClientYPosition1] = useGlobalState("clientYPosition1");
  const [isTourOpen, setIsTourOpen] = useGlobalState("IsTourOpen");
  const [isHomeButtonClick, setIsHomeButtonClick] =
    useGlobalState("IsHomeButtonClick");
  const [playAndPause, setPlayAndPause] = useGlobalState("playAndPause");
  const gaEventTracker = useAnalyticsEventTracker("ToolBarMenu");
  const [anchorEl, setAnchorEl] = useState(null);
  const [scene, setScene] = useGlobalState("scene");
	const [isLoading, setIsLoading] = useGlobalState("IsLoading");

  let alignItems = false;

	let WelcomeData = [
		`Welcome to the ${props.hostelName} Reimagined experience!`,
		"Explore opportunities to create a legacy",
		"Access all information using this menu",
		"Explore visual stories interactively",
		"Use 'Reset' to go back to the default view",
		`Let's start with a ${props.hostelName} Reimagined Tour`,
	];
	let WelcomeData1 = [
		"How can you help shape the hostel that helped shape your life?",
		"Each hotspot represents a one-time opportunity to create a legacy. Click to get a complete overview.",
		"All of the information and stories are organized and accessible from the menu.",
		"See the big picture. See the interconnections. Deep-dive. Watch it, hear it, read it.",
		"Select any Use case to get a complete overview of the use case",
		"You can stop the tour anytime you like using the 'stop tour' button on the bottom right.",
	];

	const [count, setCount] = useState(0);
	const handleNext = () => {
		startTransition(() => {
			if(count == 5) {
				handleSkip();
				handleTourButtonClick("tour");
			}
			setCount(count + 1);
		});
	};
	const handleSkip = () => {
		setCount(0);
		resetScreen();
	};

  const open = anchorEl;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedButton("selectedButton")
  };
  const handleMenuItemClick = () => {
    setAnchorEl(null);
    // setSelectedButton("selectedButton")
  };
  const links = new Map([
    ["needs", "btnBusinessNeeds"],
    ["principles", "btnGuidingPrinciples"],
    ["challenges", "btnSalesChallenges"],
    ["solutions", "btnStoryProcSolutions"],
    ["use_case_stories", "btnUseCasesEnabled"]
  ]);

  const hostelToUc = new Map([
    ["Kumaon", 1],
    ["Jwalamukhi", 2],
    ["Aravali", 3],
    ["Karakoram", 4],
    ["Nilgiri", 5]
  ]);

  // Set screen to initial state
  const resetScreen = () => {
		resetLights(scene);
		if(ui_Element == "welcome") {
			setUI_Element("");
		}
    setGlobalState("IsBackgroundBlur", false);
    setGlobalState("IsButtonContainer", true);
    setTourState(false);
		setCount(0);
    // setUseCaseMapping(false);
    setSelectedButton(null);
    setShowCardContainer(false);
    // setUI_Element(null);
    setGlobalState("useCase", 0);
    setGlobalState("solutionsId", -1);
    // setGlobalState("mapped_use_case", null);
    // setGlobalState("HoverId",0);
    setGlobalState("HoverUseCaseId", 0);
    setShowUC(false);
    setGlobalState("showDC", false);
    setGlobalState("showUC", false);
    // props.resetCamera();
    Howler.stop();
  };

  useEffect(() => {
    if (selectedButton == "tour" && isTourOpen == false) {
      setSelectedButton(null);
    }
  }, [isTourOpen]);

  const handleTourButtonClick = (buttonId) => {
    setGlobalState("IsBackgroundBlur", false);
    if (selectedButton === buttonId) {
      if (isTourOpen && isLoading) {
        setTourState(false);
        Howler.stop();
        setGlobalState("UCTourId", 0);
        setGlobalState("IsTourOpen", false);
        // document.getElementById("close-btn").click();
        props.resetCamera();
      } // if same button clicked again, reset screen
      resetScreen();
      return;
    } else if (!isTourOpen) {
      setTourState(true);
      setSelectedButton(buttonId);
      setUI_Element("");
      setShowCardContainer(false);
      setGlobalState("IsTourOpen", true);
      setGlobalState("useCase", 0);
      Howler.stop();
    }
  };

  useEffect(() => {
    if (toPress != null) {
      if (toPress === "tour") {
        handleTourButtonClick(toPress);
      } else {
        handleButtonClick(links.get(toPress));
      }
    }
  }, [toPress]);

  const handlePlayStory = () => {
    if (HoverId > 0 && HoverId < 10) {
      setGlobalState("solutionsId", HoverId.toString());
      handleSolutionsButtonClick("btnProducts", HoverId.toString());
      setGlobalState("IsButtonContainer", false);
    }
  }

  const handleSolutionsButtonClick = (buttonId, HoverId) => {
    if (selectedButton === buttonId) {
      // if same button clicked again, reset screen
      resetScreen();
      return;
    }
    setGlobalState("IsButtonContainer", false);
    setShowCardContainer(true);
    setGlobalState("solutionsId", HoverId);
    setUseCaseMapping(false);
    handleButtonClick(buttonId);
    setGlobalState("useCase", 0);
    setGlobalState("HoverUseCaseId", 0);
    setGlobalState("IsTourOpen", false);
    setSectionData(extraData[6][0].Solutions);
    setButtonType("P");
    setGlobalState("showDC", false);
    setGlobalState("showUC", false);
    setUI_Element("popuptoolbar");
  };

  useEffect(() => {
    if (isHomeButtonClick) {
      // setUI_Element("");
      setGlobalState("useCase", 0);
      setGlobalState("HoverUseCaseId", 0);
      setSelectedButton(null);
      // setGlobalState("IsButtonContainer", false);
    }
  }, [isHomeButtonClick]);

  async function fetchData() {
    for (var id = 1; id <= 9; id++) {
      var baseAPIUrl;
      var address;
      // id = 1-4 for sections, 5-9 for Solutions, 9 for use cases
      if (id === 9) {
        baseAPIUrl = `${BaseAPI}use_case_stories_emotion/${hostelToUc.get(props.hostelName)}`;
        address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB}` : `../../${ApplicationDB}/use_case_stories_emotion/${hostelToUc.get(props.hostelName)}.json`; //address for fetching sectiondata
      } else if (id === 5) {
        baseAPIUrl = `${BaseAPI}solutions`;
        address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB}&type=S` : `../../${ApplicationDB}/solutions/S.json`; //address for fetching sectiondata      
      } else if (id === 6) {
        baseAPIUrl = `${BaseAPI}solutions`;
        address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB}&type=F` : `../../${ApplicationDB}/solutions/F.json`; //address for fetching sectiondata 
      } else if (id === 7) {
        baseAPIUrl = `${BaseAPI}solutions`;
        address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB}&type=P` : `../../${ApplicationDB}/solutions/P.json`; //address for fetching sectiondata
      } else {
        baseAPIUrl = `${BaseAPI}section/`;
        address = !packageApp ? `${baseAPIUrl + id}?db=${ApplicationDB}` : `../../${ApplicationDB}/section/${id}.json`; //address for fetching sectiondata
      }
      // CHANGES HERE
      try {
        const response = await fetch(address); //fetch section data files for specific config id
        const data = await response.json();
        extraData[id - 1].push(data);
        if (id === 9) {
          setGlobalState("use_case_list", data);
        }
        if (id === 7) {
          extraData[6][0].Solutions.push({ "id": `divider`, "short_label": "", "soln_type": "", "details_url": "", "long_desc": "" });
          baseAPIUrl = `${BaseAPI}solutions`;
          address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB}&type=H` : `../../${ApplicationDB}/solutions/H.json`; //address for fetching sectiondata
          const resp = await fetch(address);
          const data = await resp.json();
          const sols = data.Solutions;
          sols.forEach(sol => {
            extraData[6][0].Solutions.push(sol);
          });
        }
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    }
  }


  async function fetchAudio() {
    const baseAPIUrl = `${BaseAPI}use_case_list/`;
    const address = !packageApp ? `${baseAPIUrl}?db = ${ApplicationDB}` : `../../ ${ApplicationDB} /use_case_list.json`;
    const response = await fetch(address);
    const data = await response.json();

    let Vosound;
    const audioClips = new Map();
    const audio_Paths = new Map();

    data.use_case_list.forEach((uc) => {
      const id = uc.use_case_id;
      const src_url = !packageApp ?
        `${assetsLocation}${ApplicationDB}/audio/uc` + String(id) + "/" : `../../ ${ApplicationDB} /audio/uc${id} /`;
      const path = src_url + "10.mp3";
      try {
        Vosound = new Howl({
          src: path,
          html5: true,
          onpause: false,
          preload: true,
        });
        audioClips.set(id, Vosound);
        audio_Paths.set(id, path);
      } catch {
      }
    });
    setGlobalState("audioVO1", audioClips);
    setGlobalState("audioPathVO1", audio_Paths);

    const audioClips2 = new Map();
    const audio_Paths2 = new Map();
    data.use_case_list.forEach((uc) => {
      const id = uc.use_case_id;
      const src_url = !packageApp ?
        `${assetsLocation}${ApplicationDB}/audio/uc` + String(id) + "/" : `../../${ApplicationDB}/audio/uc${id}/`;
      const path = src_url + "11.mp3";
      try {
        Vosound = new Howl({
          src: path,
          html5: true,
          onpause: false,
          preload: true,
        });
        audioClips2.set(id, Vosound);
        audio_Paths2.set(id, path);
      } catch {
      }
    });
    setGlobalState("audioVO2", audioClips2);
    setGlobalState("audioPathVO2", audio_Paths2);
  }

  useEffect(() => {
    fetchData();
    fetchAudio();
  }, []);

  const handleButtonClick = async (buttonId) => {
    gaEventTracker(buttonId);

    setSelectedButton(buttonId);
    setTourState(false);
    if (!playAndPause) {
      setGlobalState("playAndPause", true);
    }
    setGlobalState("IsAutoPlay", false);
    setGlobalState("IsHomeButtonClick", false);
    setGlobalState("ApplicationDB", ApplicationDB);
    if (isTourOpen) {
      setGlobalState("UCTourId", 0);
      // document.getElementById("close-btn").click();
      props.resetCamera();
    }
    Howler.stop();
    setUI_Element("");

    setShowCardContainer(true);

    return;
  };

  const handleResetButtonClick = () => {
		resetScreen();
    props.resetCamera();
    setUseCaseMapping(false);
    setGlobalState("IsBackgroundBlur", false);
    if (MainMenuIsButtons) {
      setIsResetClick(true)
    }
    setTimeout(() => {
      setIsResetClick(false)
    }, 1000);
    setTourState(false);
    setGlobalState("solutionsId", -1);
    setSelectedButton(null);
    setShowCardContainer(false);
    setGlobalState("showDC", false);
    setGlobalState("showUC", false);
    setGlobalState("IsTourOpen", false);
    setGlobalState("UCTourId", 0);
    setGlobalState("IsHomeButtonClick", true);
    setGlobalState("HoverId", 0);
    setGlobalState("HoverUseCaseId", 0);
    setGlobalState("IsButtonContainer", true);
    Howler.stop();
    // document.getElementById("close-btn").click();
  };

  return (
    <div>
      <CSSTransition
        in={HoverId > 0}
        timeout={300} // Duration of the animation in milliseconds
        classNames="animationHover" // Your CSS class for animations
        unmountOnExit
        mountOnEnter
      >
        <div style={{ top: clientYPosition1, left: clientXPosition1 }} className="hot-spot-subMenu">
          <div>
            <div className="hover-label-text">{HoverLabel}</div>
            <hr style={{ marginTop: "3%" }} className="card-divider"></hr>
          </div>
          <div className="button-group" >
            {(isTourOpen) ? "" :
              (scene.activeCamera.name.includes("security") == false && scene.activeCamera.name.includes("cr-camera") == false) ?
                <div className="zoom-in" onClick={() => setGlobalState("currentZoomedSection", HoverId)}>Zoom-in</div>
                :
                <div className="zoom-in" onClick={() => props.resetCamera()}>Zoom-out</div>
            }
            {/* <div className="learn-more">Learn More</div> */}
            {SolutionsId == -1 ?
              <div className="learn-more" onClick={() => handlePlayStory()}>Learn More</div> :
              <div className="learn-more" onClick={() => resetScreen()}>Show Less</div>
            }
          </div>
        </div>
      </CSSTransition>

      <div style={{ display: 'flex' }}>

      <div className={`${MainMenuIsButtons ? "toolbar reset-toolbar" : "plain-reset-toolbar"} `} >
          <ToolbarButton
            forwardRef={buttonRef}
            reset = {true}
            id="reset"
            buttonId="reset"
            selectedButton={selectedButton}
            active={"reset" === selectedButton}
            buttonName="Reset the Experience"
            handleButtonClick={handleResetButtonClick}
            handleMenuClick={() => { }}
            MainMenuIsButtons={MainMenuIsButtons}
          >

            <svg width="4vh" height="4vh" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_bd_38_67)">
                <rect x="6" y="3" width="52" height="52" rx="26" fill="#D8D8D8" />
              </g>
              <path d="M31.6279 40C28.3821 40 25.6323 38.8867 23.3787 36.6601C21.1262 34.4324 20 31.714 20 28.5049C20 25.2959 21.1262 22.5764 23.3787 20.3465C25.6323 18.1155 28.3821 17 31.6279 17C33.6024 17 35.4507 17.4638 37.1728 18.3915C38.8959 19.3192 40.2846 20.6143 41.3389 22.2769V17H43V25.5921H34.3123V23.9493H40.4585C39.5936 22.3119 38.3765 21.0179 36.8073 20.0672C35.2403 19.1176 33.5138 18.6429 31.6279 18.6429C28.8594 18.6429 26.5061 19.6012 24.5681 21.5179C22.6301 23.4345 21.6611 25.7619 21.6611 28.5C21.6611 31.2381 22.6301 33.5655 24.5681 35.4821C26.5061 37.3988 28.8594 38.3571 31.6279 38.3571C33.7597 38.3571 35.6838 37.7548 37.4003 36.55C39.1168 35.3452 40.3211 33.7571 41.0133 31.7857H42.7757C42.0437 34.2456 40.6523 36.2296 38.6013 37.7378C36.5504 39.2459 34.2259 40 31.6279 40Z" fill="black" />
              <defs>
                <filter id="filter0_bd_38_67" x="0" y="-3" width="64" height="67" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feGaussianBlur in="BackgroundImageFix" stdDeviation="3" />
                  <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_38_67" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset dy="3" />
                  <feGaussianBlur stdDeviation="3" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                  <feBlend mode="normal" in2="effect1_backgroundBlur_38_67" result="effect2_dropShadow_38_67" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_38_67" result="shape" />
                </filter>
              </defs>
            </svg>

            Reset
          </ToolbarButton>

          {MainMenuIsButtons ? "" : <div className='plain-reset-divider'></div>}
          
          <ToolbarButton
            buttonId="btnWelcomeCards"
            selectedButton={selectedButton}
            reset = {true}
            active={"btnWelcomeCards" === selectedButton}
            buttonName="Welcome Screen"
            handleButtonClick={async (buttonId, buttonName) => {
              if (selectedButton === buttonId) {
                // if same button clicked again, reset screen
                resetScreen();
                return;
              }
              setCount(0);
              setUseCaseMapping(false);
              handleButtonClick(buttonId);
              // setGlobalState("useCase", 1);
              // setGlobalState("IsTourOpen", false);
              // handleUseCaseButtonClick("btnMyHostelStory");
              setGlobalState("IsButtonContainer", false);
              setGlobalState("IsHomeButtonClick", false);
              setGlobalState("ApplicationDB", ApplicationDB);
              setGlobalState("playUCDirectly", true);
              setGlobalState("IsBackgroundBlur", true);
              if (isTourOpen) {
                props.resetCamera();
              }
              Howler.stop();
              setSelectedButton(buttonId);
              setGlobalState("IsButtonContainer", false);
              setUI_Element("welcome");
              setShowCardContainer(true);
              return;
            }}
            handleMenuClick={() => { }}
            MainMenuIsButtons={MainMenuIsButtons}
          >
            How to Use
          </ToolbarButton>
        </div>


        <div
          // style={{ justifyContent: MainMenuIsButtons ? "center" : "end" }}
          className={`${MainMenuIsButtons ? "toolbar" : "plain-toolbar"} `}
        >
					{/* <ToolbarButton
            buttonId="btnWelcomeCards"
            selectedButton={selectedButton}
            active={"btnWelcomeCards" === selectedButton}
            buttonName="Welcome Screen"
            handleButtonClick={async (buttonId, buttonName) => {
              if (selectedButton === buttonId) {
                // if same button clicked again, reset screen
                resetScreen();
                return;
              }
              setUseCaseMapping(false);
              handleButtonClick(buttonId);
              // setGlobalState("useCase", 1);
              setGlobalState("IsTourOpen", false);
              // handleUseCaseButtonClick("btnMyHostelStory");
              setGlobalState("IsButtonContainer", false);
              setGlobalState("IsHomeButtonClick", false);
              setGlobalState("ApplicationDB", ApplicationDB);
              setGlobalState("playUCDirectly", true);
              if (isTourOpen) {
                props.resetCamera();
              }
              Howler.stop();
              setSelectedButton(buttonId);
              setGlobalState("IsButtonContainer", false);
              setUI_Element("welcome");
              setShowCardContainer(true);
              return;
            }}
            handleMenuClick={() => { }}
            MainMenuIsButtons={MainMenuIsButtons}
          >
            Start here  
          </ToolbarButton> */}

          {/* {MainMenuIsButtons ? "" : <div className='plain-divider'></div>} */}

          <ToolbarButton
            buttonId="btnMyHostelStory"
            selectedButton={selectedButton}
            active={"btnMyHostelStory" === selectedButton}
            buttonName="My Hostel Story"
            handleButtonClick={async (buttonId, buttonName) => {
              if (selectedButton === buttonId) {
                // if same button clicked again, reset screen
                resetScreen();
                return;
              }
              setUseCaseMapping(false);
              handleButtonClick(buttonId);
              // setGlobalState("useCase", 1);
              setGlobalState("IsTourOpen", false);
              // handleUseCaseButtonClick("btnMyHostelStory");
              setGlobalState("IsButtonContainer", false);
              setGlobalState("IsHomeButtonClick", false);
              setGlobalState("ApplicationDB", ApplicationDB);
              setGlobalState("playUCDirectly", true);
              if (isTourOpen) {
                props.resetCamera();
              }
              Howler.stop();
              setSelectedButton(buttonId);
              try {
                const apiurl = !packageApp ? `${BaseAPI}use_case_stories_emotion/${hostelToUc.get(props.hostelName)}?db=${ApplicationDB}` : `../../${ApplicationDB}/use_case_stories_emotion/${hostelToUc.get(props.hostelName)}.json`;
                console.log(apiurl);
                if (extraData[8].length == 0) {
                  const response = await fetch(apiurl);
                  const data = await response.json();
                  extraData[8][0] = data;
                }
              } catch (error) {
                console.error("Error fetching data:", error);
              }
              setSectionData(extraData[8][0]);
              setButtonType("Use_case");
              setGlobalState("IsButtonContainer", false);
              setUI_Element("popuptoolbar");
              setShowCardContainer(true);
              setGlobalState("HoverUseCaseId", hostelToUc.get(props.hostelName));
              return;
            }}
            handleMenuClick={() => { }}
            MainMenuIsButtons={MainMenuIsButtons}
          >
            Introduction
          </ToolbarButton>

          {MainMenuIsButtons ? "" : <div className='plain-divider'></div>}
          <ToolbarButton
            forwardRef={buttonRef}
            buttonId="tour"
            id="tour"
            selectedButton={selectedButton}
            active={"tour" === selectedButton}
            buttonName="Immersive Overview"
            handleButtonClick={() => { handleTourButtonClick("tour"); }}
            handleMenuClick={() => { }}
            MainMenuIsButtons={MainMenuIsButtons}
          >
            {isTourOpen ? "End Tour" : `Explore the new ${props.hostelName}`}
          </ToolbarButton>

          {MainMenuIsButtons ? "" : <div className='plain-divider'></div>}
          <ToolbarButton
            buttonId="btnSuccessStories"
            active={"btnSuccessStories" === selectedButton}
            selectedButton={selectedButton}
            buttonName="StoryProc Solutions"
            handleButtonClick={(buttonId, buttonName) => {
              if (selectedButton === buttonId) {
                // if same button clicked again, reset screen
                resetScreen();
                // return;
              }
              setUseCaseMapping(false);
              setGlobalState("IsButtonContainer", true);
              handleButtonClick(buttonId);
              setGlobalState("useCase", 0);
              setGlobalState("HoverUseCaseId", 0);
              setGlobalState("IsTourOpen", false);
              setSectionData(extraData[4][0].Solutions);
              setButtonType("S");
              setGlobalState("showDC", false);
              setGlobalState("showUC", false);
              setUI_Element("popuptoolbar");
            }}
            handleMenuClick={handleClick}
            MainMenuIsButtons={MainMenuIsButtons}
          >
            Donor Walls
          </ToolbarButton>

          {MainMenuIsButtons ? "" : <div className='plain-divider'></div>}
          <ToolbarButton
            buttonId="btnOccasions"
            selectedButton={selectedButton}
            active={"btnOccasions" === selectedButton}
            buttonName="Business Needs"
            handleButtonClick={(buttonId, buttonName) => {
              if (selectedButton === buttonId) {
                // if same button clicked again, reset screen
                resetScreen();
                return;
              }
              setUseCaseMapping(false);
              handleButtonClick(buttonId);
              setGlobalState("IsBackgroundBlur", true);
              setGlobalState("useCase", 0);
              setGlobalState("HoverUseCaseId", 0);
              setGlobalState("IsTourOpen", false);
              setSectionData(extraData[1][0].SectionData);

              setUI_Element("");
              setUI_Element("cards");
            }}
            handleMenuClick={() => { }}
            MainMenuIsButtons={MainMenuIsButtons}
          >
            Mark an Occasion
          </ToolbarButton>

          {/* {MainMenuIsButtons ? "" : <div className='plain-divider'></div>}
        <ToolbarButton 
          buttonId="btnHowToDonate"
          selectedButton={selectedButton}
          active={"btnHowToDonate" === selectedButton}
          buttonName="Business Needs"
          handleButtonClick={(buttonId,buttonName) => {
						if (selectedButton === buttonId) {
							// if same button clicked again, reset screen
							resetScreen();
							return;
						}
						setUseCaseMapping(false);
            handleButtonClick(buttonId);
            setGlobalState("IsBackgroundBlur", true);
            setGlobalState("useCase", 0);
            setGlobalState("HoverUseCaseId", 0);
            setGlobalState("IsTourOpen", false);
            setSectionData(extraData[2][0].SectionData);

            setUI_Element("");
            setUI_Element("cards");
          }}
          handleMenuClick={() => {}}
					MainMenuIsButtons = {MainMenuIsButtons}
        >
          Why Donate
        </ToolbarButton>
         */}

          {MainMenuIsButtons ? "" : <div className='plain-divider'></div>}
          <ToolbarButton
            buttonId="btnProducts"
            active={"btnProducts" === selectedButton}
            selectedButton={selectedButton}
            buttonName="StoryProc Solutions"
            handleButtonClick={(buttonId, buttonName) => {
              if (selectedButton === buttonId) {
                // if same button clicked again, reset screen
                resetScreen();
              }
              setGlobalState("IsButtonContainer", true);
              setShowCardContainer(true);
              setGlobalState("solutionsId", -1);
              setUseCaseMapping(false);
              handleButtonClick(buttonId);
              setGlobalState("useCase", 0);
              setGlobalState("HoverUseCaseId", 0);
              setGlobalState("IsTourOpen", false);
              setSectionData(extraData[6][0].Solutions);
              setButtonType("P");
              setGlobalState("showDC", false);
              setGlobalState("showUC", false);
              setUI_Element("popuptoolbar");
            }}
            handleMenuClick={handleClick}
            MainMenuIsButtons={MainMenuIsButtons}
          >
            My Hostel, My Legacy
          </ToolbarButton>

        </div>
      </div>



      {/* Display elements if clicked */}

      {/* Cards or DVS tab */}
      <MenuDispensor
        buttonType={buttonType}
        sectionData={sectionData}
        ui_element={ui_Element}
        buttonId={selectedButton}
        useCaseMapping={useCaseMapping}
        handleMenuItemClick={handleMenuItemClick}
        anchorEl={anchorEl}
        handleClose={handleClose}
        open={open}
        alignItems={alignItems}
        showCardContainer={showCardContainer}
				WelcomeData={WelcomeData}
				WelcomeData1={WelcomeData1}
				count={count}
				handleNext={handleNext}
				handleSkip={handleSkip}
      />

      {/* UseCases/Guided Tour tab */}
      {/* {showTour && fetched && <UseCase steps={stepData} useCaseID={5} />} */}
    </div>
  );
};

export default MainPage;