import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import HomeComponent from './HomeComponent';

function AnimatedRoutes() {
    const location = useLocation();

		return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>	
                <Route path="/" element={<HomeComponent hostelName={"Kumaon"} />} />
                <Route path="/kumaon" element={<HomeComponent hostelName={"Kumaon"} />} />
                <Route path="/vindhyachal" element={<HomeComponent hostelName={"Vindhyachal"} />} />
                <Route path="/aravali" element={<HomeComponent hostelName={"Aravali"} />} />
                <Route path="/jwalamukhi" element={<HomeComponent hostelName={"Jwalamukhi"} />} />
                <Route path="/nilgiri" element={<HomeComponent hostelName={"Nilgiri"} />} />
                <Route path="/karakoram" element={<HomeComponent hostelName={"Karakoram"} />} />
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes